.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #74ebd5, #acb6e5);
    font-family: Arial, sans-serif;
}

.auth-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-title {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.auth-input {
    width: 100%;
    max-width: 300px; /* Limit the width of the inputs */
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.auth-input:focus {
    outline: none;
    border-color: #74ebd5;
    box-shadow: 0 0 5px rgba(116, 235, 213, 0.5);
}

.auth-button {
    width: 100%;
    max-width: 300px; /* Limit the width of the button */
    padding: 0.75rem;
    background: #74ebd5;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
}

.auth-button:hover {
    background: #57c7b8;
}

.auth-button:focus {
    outline: none;
}

.auth-switch-button {
    width: 100%;
    max-width: 300px;
    padding: 0.75rem;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 1rem;
}

.auth-switch-button:hover {
    background: #ddd;
}

.auth-switch-button:focus {
    outline: none;
}
