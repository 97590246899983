.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-buttons {
    display: flex;
    gap: 20px;
}

.nav-button {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    transition: color 0.3s, background-color 0.3s;
}

.nav-button:hover {
    color: white;
    background-color: green;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-icon {
    height: 30px;
    width: 30px;
}

.logo-text {
    margin-left: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #388e3c;
    text-transform: uppercase;
    transition: color 0.3s ease, transform 0.3s ease;
}

